import apiService from 'services/api'
import { downloadFile } from 'utils/csvDownloader'
import ExportModal, { ExportModalProps } from './ExportModal'

export default function ContactExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportContacts = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportContacts(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Contacts - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const columns = [
    {
      title: 'Contact Information',
      elements: [
        ['name', 'Name'],
        ['email', 'Email Address'],
        ['phone_number', 'Phone Number'],
        ['url', 'Website'],
        ['linkedin_url', 'LinkedIn'],
        ['company', 'Company'],
        ['domain', 'Domain'],
        ['title', 'Title'],
        ['seniority', 'Seniority'],
        ['department', 'Department'],
        ['labels', 'Contact Labels'],
      ],
    },
    {
      title: 'Activity',
      elements: [
        ['created', 'Date Added'],
        ['modified', 'Date Last Modified'],
        ['notes', 'Notes'],
      ],
    },
    {
      title: 'Account Details',
      elements: [
        ['sales_stage', 'Sales Stage'],
        ['account_owners', 'Account Owner'],
        ['tags', 'Company Tags'],
        ['deal_count', 'Deals Count'],
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Contacts Download"
      subTitle="Download up to 10,000 contacts at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Download Contacts"
      columns={columns}
      exportAPIAction={exportContacts}
      {...props}
    />
  )
}
