import { createColumnHelper } from '@tanstack/react-table'
import { FbLink } from 'components/FbUI/FbLink'
import { JsonIntoUl } from 'components/json-into-ul'
import { usePreferences } from 'context/preferences/PreferencesContext'
import {
  ContactResponse,
  ContactSource,
  ContactSourceLabels,
  Department,
  DepartmentLabels,
  EmailValidity,
  EmailValidityLabels,
  Seniority,
  SeniorityLabels,
} from 'models/contacts'
import { useMemo } from 'react'
import { IoIosWarning } from 'react-icons/io'
import { MdOutlineEmail } from 'react-icons/md'
import styled from 'styled-components'
import { formatInteger, formatPhoneNumber } from 'utils/formatting'
import { getCompanyUrl } from 'utils/getCompanyUrl'
import { ConditionalTooltipWrapper } from '../../FbUI/ConditionalTooltipWrapper'
import {
  AccountOwnerCell,
  CampaignsCell,
  SaleStagesCellFromDeals,
  TaglistCell,
} from '../../FbUI/StagePill'
import { Anchor } from '../../UI/Anchor'

export const useContactsTableColumns = () => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}

  const columnHelper = createColumnHelper<ContactResponse>()

  const cols = useMemo(
    () => [
      columnHelper.accessor('email', {
        header: () => null,
        meta: {
          headerTitle: 'Email',
          isEditable: false,
          isFirstColumn: true,
          headerGroup: 'Contact Information',
        },
        size: 350,
        cell: (info) => {
          const email = info.getValue()
          if (!email) return '-'

          return (
            <div className={'flex items-center'}>
              <MdOutlineEmail
                size={20}
                color="#D0D3DD"
                style={{ marginRight: 5 }}
              />
              {email}
            </div>
          )
        },
      }),
      columnHelper.accessor('contact_company', {
        meta: {
          tooltip: tooltips?.['contacts_contact_company'],
          headerGroup: 'Contact Information',
        },
        header: 'Company',
        size: 250,
        cell: (info) => {
          const company = info.getValue()
          if (!company)
            return (
              <MissingBadge>
                <IoIosWarning size={18} color="#F55800" />
                Missing
              </MissingBadge>
            )

          const companyUrl = getCompanyUrl(info.row.original.contact_company)
          if (companyUrl) {
            return (
              <FbLink to={companyUrl} target="_blank">
                {company.name}
              </FbLink>
            )
          }

          return company.name
        },
      }),
      columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
        meta: {
          tooltip: tooltips?.['contacts_name'],
          headerGroup: 'Contact Information',
        },
        header: 'Name',
        size: 200,
      }),
      columnHelper.accessor('title', {
        meta: {
          tooltip: tooltips?.['contacts_title'],
          headerGroup: 'Contact Information',
        },
        header: 'Title',
        size: 200,
      }),
      columnHelper.accessor('seniority', {
        meta: {
          headerGroup: 'Contact Information',
        },
        header: 'Seniority',
        cell: (info) => {
          const value = info.getValue()
          if (value === null || value === undefined) return '-'

          return SeniorityLabels[value as Seniority]
        },
        size: 200,
      }),
      columnHelper.accessor('department', {
        meta: {
          headerGroup: 'Contact Information',
        },
        header: 'Department',
        cell: (info) => {
          const value = info.getValue()
          if (value === null || value === undefined) return '-'

          return DepartmentLabels[value as Department]
        },
        size: 200,
      }),
      columnHelper.accessor('notes', {
        meta: {
          tooltip: tooltips?.['contacts_notes'],
          headerGroup: 'Activity',
        },
        header: 'Notes',
        size: 350,
        cell: (info) => {
          const notes = info.getValue()
          if (!notes) return '-'

          return (
            <ConditionalTooltipWrapper
              shouldWrap={notes.length > 50}
              tooltipText={notes}
            >
              <JsonIntoUl
                value={notes}
                fallback={
                  <span style={{ wordBreak: 'break-word' }}>
                    {`${notes.slice(0, 50)}${notes.length > 50 ? '...' : ''}`}
                  </span>
                }
              />
            </ConditionalTooltipWrapper>
          )
        },
      }),
      columnHelper.accessor('url', {
        meta: {
          tooltip: tooltips?.['contacts_url'],
          headerGroup: 'Contact Information',
        },
        cell: (info) => {
          if (!info.getValue()) {
            return '-'
          }
          return (
            <Anchor href={info.getValue()} target="_blank">
              {info.getValue()}
            </Anchor>
          )
        },
        header: 'Website',
        size: 350,
      }),
      columnHelper.accessor('linkedin_url', {
        meta: {
          tooltip: tooltips?.['contacts_linkedin_url'],
          headerGroup: 'Contact Information',
        },
        cell: (info) => {
          if (!info.getValue()) {
            return '-'
          }
          return (
            <Anchor href={info.getValue()} target="_blank">
              {info.getValue()}
            </Anchor>
          )
        },
        header: 'LinkedIn',
        size: 350,
      }),

      columnHelper.accessor('phone_number', {
        meta: {
          tooltip: tooltips?.['contacts_phone_number'],
          headerGroup: 'Contact Information',
        },
        header: 'Phone Number',
        size: 225,
        cell: (info) => {
          const phone = info.getValue()
          const extension = info.row.original.extension
          return formatPhoneNumber({
            phoneNumber: phone,
            extension,
          })
        },
      }),

      columnHelper.display({
        id: 'origin_campaign',
        header: 'Requested From Campaign',
        size: 225,
        cell: (info) => {
          const campaigns = []
          if (info.row.original.requested_contact?.job.campaign) {
            campaigns.push(info.row.original.requested_contact?.job.campaign)
          }
          return <CampaignsCell campaigns={campaigns} />
        },
      }),

      columnHelper.accessor('contact_company.deal_count', {
        meta: {
          tooltip: tooltips?.['contacts_deals_count'],
          headerGroup: 'Account Details',
        },
        id: 'deal_count',
        header: 'Deals',
        size: 110,
        cell: (info) => {
          return info.row.original.contact_company.deal_count || '-'
        },
      }),

      columnHelper.accessor('contact_company.deals', {
        meta: {
          tooltip: tooltips?.['contacts_sales_stages'],
          headerGroup: 'Account Details',
        },
        id: 'sales_stages',
        header: 'Sales Stages',
        size: 260,
        cell: (info) => {
          return (
            <SaleStagesCellFromDeals
              deals={info.row.original?.contact_company?.deals}
            />
          )
        },
      }),

      columnHelper.accessor('contact_company.deals', {
        meta: {
          tooltip: tooltips?.['contacts_account_owners'],
          headerGroup: 'Account Details',
        },
        id: 'account_owners',
        header: 'Account Owners',
        size: 260,
        cell: (info) => {
          const deals = info.row.original?.contact_company?.deals
          return <AccountOwnerCell deals={deals} />
        },
      }),

      columnHelper.accessor('tags', {
        meta: {
          tooltip: tooltips?.['contacts_tags'],
          headerGroup: 'Account Details',
        },
        id: 'contact_labels',
        header: 'Contact Labels',
        size: 260,
        cell: (info) => {
          return <TaglistCell taglist={info.getValue()} />
        },
      }),

      columnHelper.accessor('contact_company.taglist', {
        meta: {
          tooltip: tooltips?.['opps_tags'],
          headerGroup: 'Account Details',
        },
        id: 'company_tags',
        header: 'Company Tags',
        size: 260,
        cell: (info) => {
          return <TaglistCell taglist={info.getValue()} />
        },
      }),

      columnHelper.accessor('company_size', {
        meta: {
          tooltip: tooltips?.['contacts_company_size'],
          headerGroup: 'Company Information',
        },
        header: 'Company Size',
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatInteger(value) : '-'
        },
      }),

      columnHelper.accessor('company_cuisine_50', {
        meta: {
          tooltip: tooltips?.['contacts_company_cuisine_50'],
          headerGroup: 'Company Information',
        },
        header: 'Company Cuisine',
        size: 200,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('source', {
        meta: {
          tooltip: tooltips?.['contact_source'],
          headerGroup: 'Contact Information',
        },
        header: 'Source',
        size: 125,
        cell: (info) => {
          const value = info.getValue()
          if (value === null || value === undefined) return '-'

          return ContactSourceLabels[value as ContactSource]
        },
      }),
      columnHelper.accessor('email_validity', {
        meta: {
          tooltip: tooltips?.['email_validity'],
          headerGroup: 'Contact Information',
        },
        header: 'Email Validity',
        size: 125,
        cell: (info) => {
          const value = info.getValue()
          if (value === null || value === undefined) return '-'

          return EmailValidityLabels[value as EmailValidity]
        },
      }),

      columnHelper.accessor('created', {
        meta: {
          tooltip: tooltips?.['contacts_created'],
          headerGroup: 'Activity',
        },
        header: 'Date added',
        size: 125,
        cell: (info) => {
          const date = info.getValue()
          if (!date) return '-'

          return new Date(date).toLocaleDateString()
        },
      }),
      columnHelper.accessor('modified', {
        meta: {
          tooltip: tooltips?.['contacts_modified'],
          headerGroup: 'Activity',
        },
        header: 'Last updated',
        size: 125,
        cell: (info) => {
          const date = info.getValue()
          if (!date) return '-'

          return new Date(date).toLocaleDateString()
        },
      }),
    ],
    [preferences, tooltips]
  )

  return cols
}

export const MissingBadge = styled.div`
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  background-color: #ffefef;
  padding: 2px 8px;
  border-radius: 16px;
  mix-blend-mode: multiply;
  color: #d31717;
`
