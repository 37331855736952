import { Page } from 'components/FbUI/Page'
import { TabsWithDropdown } from 'components/FbUI/TabsWithDropdown'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import { CompaniesTable } from 'components/Tables/CompaniesTable/CompaniesTable'
import { DoorsTable } from 'components/Tables/DoorsTable/DoorsTable'
import { UniversitiesTable } from 'components/Tables/UniversitiesTable/UniversitiesTable'
import useDocumentTitle from 'components/useDocumentTitle'
import {
  CHAINS_TABLE_KEY,
  COLLEGE_AND_UNIVERSITY_TABLE_KEY,
  DOORS_TABLE_KEY,
  getCompanyTableKey,
  K12_TABLE_KEY,
} from 'constants/tableQueryKeys'
import { OpportunitySubCategorySlug } from 'models/opportunities'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { featureFlagService } from 'utils/featureFlagService'
import LoadingScreen from '../../../components/Layout/LoadingScreen'
import { ChainsTable } from '../../../components/Tables/ChainsTable/ChainsTable'
import { K12Table } from '../../../components/Tables/K12Table/K12Table'
import { ChainsSummary, PlacesSummary } from '../../../models/summaries'
import { StatsCard, StatsContainer } from '../../../styled/Stats'
import { formatInteger, formatUsd } from '../../../utils/formatting'
import OpportunitiesTabs from './OpportunitiesTabs'
import {
  OpportunitiesProvider,
  useOpportunitiesContext,
} from './opportunities-context'
import {
  OpportunityDropdownItemValue,
  useOpportunitiesTabs,
} from './useOpportunitiesTabs'
import { LTVProcessingBanner } from 'components/Banners/LTVProcessingBanner'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import { HelpTooltip } from 'components/Tooltip/HelpTooltip'

export interface IOpportunityStat {
  name: string
  value: number | string | undefined
  isLoading: boolean | undefined
  tooltip?: string
}

export function Opportunities() {
  const navigate = useNavigate()
  const { enableCnUTable, enableK12Table } = featureFlagService()
  const { doorsTabSelected, chainsTabSelected } = useOpportunitiesContext()
  const { setLastOppVisited } = useNavbarStore()
  const {
    mainTabs,
    categoryActive,
    subCategoryActive,
    restaurantsTabsSelected,
    universitiesTabSelected,
    k12selected,
    isLoading,
  } = useOpportunitiesTabs()

  useDocumentTitle(
    `${subCategoryActive?.name} - ${categoryActive?.name} - First Bite`
  )

  const [unmatched, setUnmatched] = useState<boolean>(false)
  const [companiesCount, setCompaniesCount] = useState<number>()
  const companyStats: IOpportunityStat[] = [
    {
      isLoading: companiesCount === undefined,
      name: 'Total Companies',
      value: companiesCount,
    },
  ]

  const [placesSummary, setPlacesSummary] = useState<PlacesSummary>({
    isLoading: true,
    total_ltv: 0,
    chains_count: 0,
    places_count: 0,
  })

  const [chainsSummary, setChainsSummary] = useState<ChainsSummary>({
    isLoading: true,
    total_ltv: 0,
    count: 0,
    places_count: 0,
    avg_pounds_per_year: 0,
    avg_total_ltv: 0,
  })

  const placesStats: IOpportunityStat[] = [
    {
      name: 'Total LTV',
      value: formatUsd(placesSummary?.total_ltv),
      isLoading: placesSummary?.isLoading,
    },
    {
      name: 'Total Chains',
      value: placesSummary?.chains_count?.toLocaleString('en-US'),
      isLoading: placesSummary?.isLoading,
      tooltip:
        'The sum of unique chains for the doors matching the applied filters and/or search criteria',
    },
    {
      name: 'Total Doors',
      value: placesSummary?.places_count?.toLocaleString('en-US'),
      isLoading: placesSummary?.isLoading,
      tooltip:
        'The total number of doors that match the applied filters and/or search criteria.',
    },
  ]

  const chainStats: IOpportunityStat[] = [
    {
      name: 'Total LTV',
      value: formatUsd(chainsSummary?.total_ltv),
      isLoading: chainsSummary?.isLoading,
    },
    {
      name: 'Total Chains',
      value: formatInteger(chainsSummary?.count),
      isLoading: chainsSummary?.isLoading,
      tooltip:
        'The total number of chains that have at least 1 door that matches the applied filters and/or search criteria.',
    },
    {
      name: 'Total Doors',
      value: formatInteger(chainsSummary?.places_count),
      isLoading: chainsSummary?.isLoading,
      tooltip:
        'The sum of doors for all chains that match the applied filters and/or search criteria.',
    },
  ]

  const restStats = doorsTabSelected
    ? placesStats
    : chainsTabSelected
      ? chainStats
      : []

  const [universityCount, setUniversityCount] = useState<number>()
  const universityStats = useMemo(
    () => [
      {
        isLoading: universityCount === undefined,
        name: 'Total Universities',
        value: formatInteger(universityCount),
      },
    ],
    [universityCount]
  )

  const [k12Count, setK12Count] = useState<number>()
  const k12Stats = useMemo(
    () => [
      {
        isLoading: k12Count === undefined,
        name: 'Total K-12 Districts',
        value: formatInteger(k12Count),
      },
    ],
    [k12Count]
  )

  const renderStats = () => {
    let statsToRender: IOpportunityStat[] = []
    if (unmatched) {
      statsToRender = companyStats
    } else if (
      subCategoryActive?.slug === OpportunitySubCategorySlug.RESTAURANTS_BARS
    ) {
      statsToRender = restStats
    } else if (
      subCategoryActive?.slug === OpportunitySubCategorySlug.EDUCATION_CU
    ) {
      statsToRender = universityStats
    } else if (
      subCategoryActive?.slug === OpportunitySubCategorySlug.EDUCATION_K_12
    ) {
      statsToRender = k12Stats
    }
    return (
      <>
        <StatsContainer>
          {statsToRender.map((stat) => (
            <StatsCard key={stat.name}>
              <div className="flex justify-between w-full items-center">
                <h1> {stat.name} </h1>
                <HelpTooltip content={stat.tooltip} />
              </div>
              {!stat.isLoading ? (
                <span> {stat.value} </span>
              ) : (
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  Calculating...
                </span>
              )}
            </StatsCard>
          ))}
        </StatsContainer>
      </>
    )
  }

  const tables = useMemo(() => {
    if (unmatched) {
      return (
        <>
          {categoryActive && subCategoryActive && (
            <TableContainer>
              <CompaniesTable
                key={getCompanyTableKey(subCategoryActive.slug)}
                setTotalRowsCount={setCompaniesCount}
                pageParamName={`${subCategoryActive.slug}-page`}
                subCategory={subCategoryActive}
                category={categoryActive}
                defaultColumnVisibility={{ city: false, country: false }}
                tableKey={`${subCategoryActive.slug}-company-table`}
              />
            </TableContainer>
          )}
        </>
      )
    }

    return (
      <>
        {restaurantsTabsSelected && (
          <>
            {/* Banner saying the data is currently being processed, users might experience something? */}
            {/* use tailwind css */}
            <LTVProcessingBanner />
            <DoorChainTable
              setPlacesSummary={setPlacesSummary}
              setChainsSummary={setChainsSummary}
            />
          </>
        )}

        {enableCnUTable && universitiesTabSelected && (
          <TableContainer>
            <UniversitiesTable
              defaultColumnVisibility={{
                total_men_enrollment: false,
                total_women_enrollment: false,
              }}
              tableKey={COLLEGE_AND_UNIVERSITY_TABLE_KEY}
              setTotalRowsCount={setUniversityCount}
            />
          </TableContainer>
        )}

        {enableK12Table && k12selected && (
          <TableContainer>
            <K12Table
              tableKey={K12_TABLE_KEY}
              defaultColumnVisibility={{
                k12district__address: false,
                k12district__county: false,
                k12district__teachers_count: false,
                k12district__revenue: false,
                k12district__reduced_lunch: false,
                k12district__reduced_lunch_perc: false,
                k12district__free_lunch: false,
                k12district__free_lunch_perc: false,
                k12district__uncategorized_lunch: false,
                k12district__uncategorized_lunch_perc: false,
                deal_count: false,
                note_count: false,
                distributors: false,
              }}
              setTotalRowsCount={setK12Count}
            />
          </TableContainer>
        )}
      </>
    )
  }, [
    unmatched,
    subCategoryActive,
    categoryActive,
    universitiesTabSelected,
    k12selected,
    chainsTabSelected,
  ])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!categoryActive) {
    return <NotFoundCard />
  }

  return (
    <>
      <TabsWithDropdown
        tabs={mainTabs}
        selectedTabKey={categoryActive.slug}
        selectedDropdownItemKey={subCategoryActive?.slug ?? ''}
        setSelectedTab={(value: OpportunityDropdownItemValue) => {
          const url = `/opportunities/${value.category}/${value.subCategory}/`
          setLastOppVisited(url)
          navigate(url)
        }}
      />
      <Page>
        <Header>
          <TitleRow>
            <Title>
              {categoryActive?.name} Opportunities:{' '}
              <b>{subCategoryActive?.name}</b>
            </Title>
            <TabsContainer>
              <OpportunitiesTabs
                companyTypeSlug={subCategoryActive?.slug}
                unmatched={unmatched}
                setUnmatched={setUnmatched}
              />
            </TabsContainer>
          </TitleRow>
          {renderStats()}
        </Header>
        {tables}
      </Page>
    </>
  )
}

interface DoorsChainTableProps {
  setPlacesSummary?: (value: React.SetStateAction<PlacesSummary>) => void
  setChainsSummary?: (value: React.SetStateAction<ChainsSummary>) => void
  noPaddingTop?: boolean
}

function DoorChainTable(props: DoorsChainTableProps) {
  const { doorsTabSelected, chainsTabSelected } = useOpportunitiesContext()

  return (
    <>
      <TableContainer hide={!doorsTabSelected}>
        <DoorsTable
          setDoorsSummary={props.setPlacesSummary}
          tableKey={DOORS_TABLE_KEY}
          defaultColumnVisibility={{
            zipcode: false,
            total_ltv: false,
            brand_ltv: false,
            revenue_ltv: false,
            one_year_total_value: false,
            taro: false,
            tabo: false,
            pounds_per_year: false,
            instagram_following: false,
            instagram_posts: false,
            dma: false,
            menu_url: false,
            menu_size: false,
            google_place_url: false,
            note_count: false,
            deal_count: false,
            sales_stages: false,
            account_owners: false,
            tags: false,
            distributors: false,
            menu_ingredients: false,
            full_address: false,
            contact_count: false,
            country: false,
          }}
        />
      </TableContainer>

      <TableContainer hide={!chainsTabSelected}>
        <ChainsTable
          defaultColumnVisibility={{
            total_ltv: false,
            brand_ltv: false,
            revenue_ltv: false,
            one_year_total_value: false,
            taro: false,
            tabo: false,
            pounds_per_year: false,
            note_count: false,
            deal_count: false,
            sales_stages: false,
            account_owners: false,
            tags: false,
            distributors: false,
            median_hhi: false,
            pop_density: false,
            hh_gt100k: false,
            reputation_data: false,
            michelin_stars_count: false,
            instagram_following: false,
            instagram_posts: false,
            instagram_text_percentile: false,
            contact_count: false,
            menu_size: false,
            countries_summary: false,
          }}
          tableKey={CHAINS_TABLE_KEY}
          setChainsSummary={props.setChainsSummary}
        />
      </TableContainer>
    </>
  )
}

export function OpportunitiesPage() {
  return (
    <OpportunitiesProvider>
      <Opportunities />
    </OpportunitiesProvider>
  )
}

const Header = styled.div`
  padding: 12px 25px 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
`

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  align-items: 'start';
`

export const Title = styled.h1`
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 12px;
`

// table container height based on
// footer height + navbar height + table header height
const TableContainer = styled.div<{ hide?: boolean }>`
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  height: calc(100vh - 270px);
`

export const TabsContainer = styled.div`
  position: absolute;
  bottom: -12px;
  width: 100%;
`
