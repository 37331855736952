import { useQuery } from '@tanstack/react-query'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import to from 'await-to-js'
import { AxiosError } from 'axios'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import TableButtonProduct from 'components/Buttons/TableButtons/TableButtonProduct'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnSort, IColumnVisibility } from 'components/DataTable/types'
import { FbLink } from 'components/FbUI/FbLink'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import DistributorAssignModal from 'components/Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from 'components/Modals/AssignmentModal/TagAssignModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import {
  CreateContactRequestJobModal,
  MAX_OPPORTUNITIES_REQUESTED,
} from 'components/Modals/CreateContactRequestJobModal'
import ChainExportModal from 'components/Modals/ExportModal/ChainExportModal'
import MenuModal from 'components/Modals/MenuModal/MenuModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import { OverflownText } from 'components/OverflownText'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { PredictedTrafficBadge } from 'components/PredictedTrafficBadge/PredictedTrafficBadge'
import { ChainsFilterset } from 'components/Tables/ChainsTable/ChainsFilterset'
import { CHAIN_SUMMARY_KEY } from 'constants/tableQueryKeys'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import { menu_ingredients } from 'models/menu_ingredients'
import { ChainsSummary } from 'models/summaries'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiFoodMenu } from 'react-icons/bi'
import {
  BsBriefcase,
  BsDownload,
  BsPhone,
  BsTags,
  BsTruck,
  BsXCircle,
} from 'react-icons/bs'
import apiService from 'services/api'
import {
  bulkEditDistributorsParams,
  bulkEditTagsParams,
} from 'services/apiTypes'
import { notifyError } from 'services/toast'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { useSelectedProductIds } from 'stores/SelectedProductsStore/SelectedProductsStore'
import styled from 'styled-components'
import { featureFlagService } from 'utils/featureFlagService'
import { paramsContainAccountFilters } from 'utils/filterUtils'
import { formatInteger, formatPercentage, formatUsd } from 'utils/formatting'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { AssignToCampaignModal } from '../../../features/campaigns/AssignToCampaignModal'
import { useCampaignUnassigner } from '../../../features/campaigns/useCampaignUnassigner'
import { Chain } from '../../../models/chains'
import { FilterIdentifier } from '../../../models/saved_view'
import { useOpportunitiesContext } from '../../../pages/app/opportunities/opportunities-context'
import { queryClient } from '../../../services/queryClient'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { CreateDealModalForTable } from '../../Deals/CreateDealModal/CreateDealModal'
import {
  AccountOwnerCell,
  CampaignsCell,
  DistributorsCell,
  SaleStagesCellFromDeals,
  TaglistCell,
} from '../../FbUI/StagePill'
import { TableSearchWithElastic } from '../../Filters/TableSearchWithElastic'
import { Anchor } from '../../UI/Anchor'
import { IDropdownItem } from '../../UI/Dropdown/Dropdown'
import { Switch } from '../../UI/Switch/Switch'
import { ChangeProductViewModal } from '../../change-product-view-modal'
import * as S from '../CommonTable.styles'

import { cn } from '../../UI/cn'

const sortableFields = [
  'chain',
  'count',
  'total_ltv',
  'brand_ltv',
  'revenue_ltv',
  'pounds_per_year',
  'domain',
  'cuisine_50',
  'expense_category',
  'reviews_count',
  'rating',
  'velocity_group',
  'deal_count',
  'contact_count',
  'taro',
  'tabo',
  'one_year_total_value',
  'distributors',
  'menu_length',
  'menu_matches',
  'michelin_stars_count',
  'sales_stages',
  'state_summary_length',
  'instagram_text_percentile',
]

const PAGE_SIZE = 100

interface IChainTable {
  setChainsSummary?: (summary: ChainsSummary) => void
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
}

function ChainsTableComponent(props: IChainTable) {
  const api = apiService()
  const featureFlag = featureFlagService()
  const hasCampaign = !!props.baseFilters?.campaign
  const IDENTIFIER = 'CHAINS_TABLE' + (props.filterIdentifierModifier ?? '')
  const chainsFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )

  const {
    state: { sorting, rowSelection, totalSelectedRows, isAllRowsSelected },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const { setRestaurantsView, chainsTabSelected } = useOpportunitiesContext()

  const [pagination, setPagination] = usePaginationURLParams(PAGE_SIZE)
  const [showProductSelection, setShowProductSelection] = useState(false)
  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [showContactRequest, setShowContactRequest] = useState(false)
  const [menuModal, setMenuModal] = useState<
    | {
        chainId: number
        chainName?: string
        itemsIds?: number[]
        menuLength?: number
      }
    | undefined
  >(undefined)

  const selectedProductsIds = useSelectedProductIds()

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filtersAsParams = {
    ...useFilterParams(chainsFilterStore),
    ...props.baseFilters,
  }

  const filterAndSortParams = useMemo(() => {
    return {
      ...filtersAsParams,
      ...sortParams,
    }
  }, [filtersAsParams, sorting, props.baseFilters])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
    products: selectedProductsIds,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    staleTime: Infinity,
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      const [err, res] = await to(
        api.getChainsListV2(
          {
            ...filterAndSortParams,
            page: pagination.pageIndex + 1,
            product_id: selectedProductsIds,
          },
          signal
        )
      )

      if ((err as AxiosError)?.code === 'ERR_NETWORK') {
        notifyError('Sorry! Unable to complete this search, please try again.')
      }
      if (err) throw err

      return res
    },
  })
  // END DATA FETCHING

  const unassignFromCampaign = useCampaignUnassigner('chain', {
    data: data?.results ?? [],
    accessorKey: 'contact_company_id',
    tableQueryKey: TABLE_QUERY_KEY,
    requestParams: filterAndSortParams,
    campaignId: props?.baseFilters?.campaign,
  })

  useEffect(() => {
    clearSelectedRows()
  }, [data])

  const [openDealModal, setOpenDealModal] = React.useState(false)
  const [dealChainProxyIds, setDealChainProxyIds] = React.useState<number[]>([])

  const summaryAbortControllerV2 = useMemo(() => new AbortController(), [])

  const totalRowsSelected = useMemo(() => {
    return isAllRowsSelected
      ? totalSelectedRows
      : Object.keys(rowSelection).length
  }, [isAllRowsSelected, rowSelection])

  const { data: chainsSummary, isLoading: chainsSummaryLoading } =
    api.useGetChainsSummaryV2(
      {
        ...filtersAsParams,
        product_id: selectedProductsIds.filter((p) => p !== -1),
      },
      summaryAbortControllerV2.signal
    )

  useEffect(() => {
    if (chainsSummaryLoading) return
    props.setChainsSummary?.({
      total_ltv: chainsSummary?.total_ltv ?? 0,
      places_count: chainsSummary?.places_count ?? 0,
      count: chainsSummary?.count ?? 0,
      avg_pounds_per_year: chainsSummary?.avg_pounds_per_year ?? 0,
      avg_total_ltv: chainsSummary?.avg_total_ltv ?? 0,
      isLoading: chainsSummaryLoading,
    })
    setTotalRowsInBackend(chainsSummary?.count ?? 0)
  }, [chainsSummaryLoading, chainsSummary])

  const handleCreateDeals = () => {
    if (!data) return
    const chainProxyIds = Object.keys(rowSelection).map(
      (idx) => data.results[parseInt(idx)].contact_company_id
    )
    setDealChainProxyIds(chainProxyIds)
    setOpenDealModal(true)
  }

  const [showExportModal, setShowExportModal] = useState<boolean>(false)
  const [showChainExportModal, setShowChainExportModal] =
    useState<boolean>(false)

  const selectedChainIds = useMemo(() => {
    const accessor = 'id'
    return (
      data?.results

        ?.filter((_: any, i: number) => rowSelection[i])

        ?.map((r: any) => {
          return accessor.split('.').reduce((acc, key) => acc[key], r)
        }) ?? []
    )
  }, [data, rowSelection])

  const columns = useChainsTableColumns(
    chainsSummary,
    setMenuModal,
    sorting,
    data,
    selectedProductsIds
  )

  const refetchChainsSummary = useCallback(() => {
    if (paramsContainAccountFilters(filterAndSortParams)) {
      queryClient.refetchQueries({ queryKey: [CHAIN_SUMMARY_KEY] })
    }
  }, [filterAndSortParams, queryClient])

  const apiTagsAction = useCallback(
    async (values: bulkEditTagsParams) => {
      const apiAction = api.chainsBulkEditTagsV2
      const response = await apiAction(values)
      refetchChainsSummary()
      return response
    },
    [api, refetchChainsSummary]
  )

  const apiDistributorsAction = useCallback(
    async (values: bulkEditDistributorsParams) => {
      const apiAction = api.chainsBulkEditDistributorsV2
      const response = await apiAction(values)
      refetchChainsSummary()
      return response
    },
    [api, refetchChainsSummary]
  )

  const actions = useMemo(() => {
    const items: IDropdownItem[] = [
      {
        label: 'Create Deals',
        icon: <BsBriefcase size={20} />,
        callback: handleCreateDeals,
      },
      {
        label: 'Manage Tags',
        icon: <BsTags size={20} />,
        callback: () => setShowAssignTags(true),
      },
      {
        label: 'Add Known Distributors',
        icon: <BsTruck size={20} />,
        callback: () => setShowAssignDistributors(true),
      },
      {
        label: 'Export Selected',
        icon: <BsDownload size={20} />,
        callback: () => setShowExportModal(true),
      },
    ]

    if (featureFlag.enableContactRequests) {
      items.push({
        label: 'Request Contacts',
        icon: <BsPhone size={20} />,
        callback: () => setShowContactRequest(true),
        disabled: totalRowsSelected > MAX_OPPORTUNITIES_REQUESTED,
        disabledTooltipText: `To request contacts, select less than ${MAX_OPPORTUNITIES_REQUESTED} opportunities.`,
      })
    }

    if (featureFlag.enableCampaigns) {
      if (!hasCampaign) {
        items.push({
          label: 'Push to Campaign',
          icon: <BsDownload size={20} />,
          subContent: (
            <AssignToCampaignModal
              data={data?.results ?? []}
              accessorKey={'contact_company_id'}
              tableQueryKey={TABLE_QUERY_KEY}
              requestParams={filterAndSortParams}
              companyTypeSlug={'restaurants-bars'}
              buttonLess
            />
          ),
        })
      } else {
        items.push({
          label: 'Unassign from Campaign',
          icon: <BsXCircle size={20} />,
          callback: () => unassignFromCampaign(),
        })
      }
    }

    return items
  }, [data, featureFlag, hasCampaign])

  return (
    <TableContainer>
      <S.SearchContainer>
        <div className="flex w-full gap-2 items-center">
          <TableSearchWithElastic
            filterStore={chainsFilterStore}
            historyKey="chains"
            options={[
              {
                label: 'Menu',
                searchKey: 'menu',
                searchPlaceholder: 'Type menu item',
              },
              {
                label: 'Name',
                searchKey: 'chain_name',
                searchPlaceholder: 'Type chain name',
              },
            ]}
          />

          {!hasCampaign && (
            <Switch
              type={'checkbox'}
              checked={chainsTabSelected}
              onChange={(e) => {
                if (e.target.checked) {
                  setRestaurantsView('chain')
                } else {
                  setRestaurantsView('door')
                }
              }}
              labelRight={chainsTabSelected ? 'Chain View' : 'Door View'}
              className={'min-w-[120px]'}
            />
          )}

          <TableButtonProduct
            selectedProducts={selectedProductsIds}
            onClick={() => setShowProductSelection(true)}
          />
          <ChainsFilterset filterIdentifier={IDENTIFIER as FilterIdentifier} />
          <ColumnSelectorRecipient tableKey={props.tableKey} />
        </div>

        <div className={cn('flex flex-row justify-between gap-4')}>
          <TableButtonAction
            items={actions}
            disabled={!totalSelectedRows}
            selectedRowsCount={totalSelectedRows ?? 0}
          />
          <FilterChips
            identifier={IDENTIFIER as FilterIdentifier}
            store={chainsFilterStore}
            showActive
            clearAllButton
          />
        </div>
      </S.SearchContainer>

      <CreateContactRequestJobModal
        data={data?.results ?? []}
        open={showContactRequest}
        onClose={() => setShowContactRequest(false)}
        // v1 and v2 keys
        accessorKeys={['contact_company_id', 'contact_company[0].id']}
        tableQueryKey={TABLE_QUERY_KEY}
        requestParams={filterAndSortParams}
        companyTypeSlug={'restaurants-bars'}
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={apiTagsAction}
        idAccessor="id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={apiDistributorsAction}
      />

      <ChainExportModal
        count={
          (showExportModal ? selectedChainIds?.length : chainsSummary?.count) ??
          0
        }
        show={showExportModal || showChainExportModal}
        _handleClose={() => {
          setShowExportModal(false)
          setShowChainExportModal?.(false)
        }}
        filters={filterAndSortParams}
        productSelected={selectedProductsIds[0]}
        selectedIds={
          showExportModal && !isAllRowsSelected ? selectedChainIds : undefined
        }
      />

      {menuModal?.chainId && (
        <MenuModal
          show={!!menuModal}
          handleClose={() => setMenuModal(undefined)}
          chainId={menuModal.chainId!}
          chainName={menuModal.chainName!}
          menuLength={menuModal?.menuLength}
          filterStore={chainsFilterStore}
        />
      )}

      <DataTable
        tableKey={props.tableKey}
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={[
          ...sortableFields,
          ...(featureFlag.enableTagsSort ? ['tags'] : []),
        ]}
        virtualizeRows
        enableRowSelection
        isPaginationEnabled={true}
        defaultSort={[{ id: 'count' as any, desc: true }]}
        paginationOptions={{
          pageCount: Math.ceil((chainsSummary?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText="Select Opportunities"
        footerControls={
          <OpportunitiesTableFooterControls
            companyTypeSlug={'restaurants-bars'}
            onDownloadButtonClick={() =>
              chainsTabSelected
                ? setShowChainExportModal(true)
                : setShowExportModal(true)
            }
          />
        }
      />

      <CreateDealModalForTable
        companyIds={dealChainProxyIds}
        show={openDealModal}
        handleClose={() => setOpenDealModal(false)}
        queryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="contact_company_id"
        optimisticUiTarget="chains"
        filterAndSortParams={filterAndSortParams}
        onDealCreated={refetchChainsSummary}
        originCampaignId={props.baseFilters?.campaign}
        companyTypeSlug="restaurants-bars"
      />

      <ChangeProductViewModal
        show={showProductSelection}
        handleClose={() => setShowProductSelection(false)}
      />
    </TableContainer>
  )
}

export function ChainsTable(
  props: IChainTable & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Predicted Values',
        'Reputation Data',
        'Location Data',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <ChainsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

function useChainsTableColumns(
  chainsSummary: ChainsSummary | undefined,
  setMenuModal: any,
  sorting: IColumnSort<object>[] | undefined,
  data: any,
  selectedProducts: number[]
) {
  const featureFlag = featureFlagService()
  const { preferences } = usePreferences()

  const tooltips: Record<string, any> = preferences?.tooltips || {}

  const defaultCell = (info: CellContext<Chain, any>) => {
    const value = info.getValue()
    if (!value) return '-'
    return value
  }

  const columns = useMemo<ColumnDef<Chain, any>[]>(() => {
    let cols = [
      {
        accessorKey: 'chain',
        header: () => null,
        meta: {
          isEditable: false,
          isFirstColumn: true,
          headerTitle: 'Chain',
          headerGroup: 'Opportunity Attributes',
        },
        cell: (info) => {
          if (!info.getValue()) return '-'
          return (
            <FbLink
              to={`/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${info.row.original.id}`}
              target="_blank"
            >
              {info.getValue()}
            </FbLink>
          )
        },
        size: 240,
      },
      {
        id: 'count',
        meta: {
          tooltip: tooltips?.['opps_chain_count'],
          rightAlign: true,
          headerGroup: 'Opportunity Attributes',
        },
        accessorKey: 'count',
        cell: (info) => {
          return info.getValue ? formatInteger(info.getValue()) : '-'
        },
        header: 'Chain Size',
        size: 110,
      },
      {
        id: 'menu',
        meta: {
          tooltip: tooltips?.['opps_menu'],
          headerGroup: 'Opportunity Attributes',
        },
        accessorKey: 'menu',
        size: 100,
        header: 'Menu',
        cell: (info) => {
          const items = info.row.original.search_items?.map(
            (item) => item.item_id
          )
          const menuLength = info.row.original.menu_length

          if (!menuLength)
            return (
              <div className="ml-1">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Unavailable</Tooltip>}
                >
                  <div className="max-w-max">
                    <BiFoodMenu size={20} className="text-gray-400" />
                  </div>
                </OverlayTrigger>
              </div>
            )

          return (
            <div className="ml-1">
              <BiFoodMenu
                size={20}
                onClick={() =>
                  setMenuModal({
                    chainId: info.row.original.id,
                    chainName: info.row.original.chain,
                    itemsIds: items,
                    menuLength,
                  })
                }
                className="cursor-pointer"
              />
            </div>
          )
        },
      },
      {
        id: 'menu_length',
        accessorKey: 'menu_length',
        meta: {
          tooltip: tooltips?.['opps_menu_size'],
          rightAlign: true,
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Menu Size',
        size: 100,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        id: 'menu_matches',
        accessorKey: 'match_count',
        meta: {
          tooltip: tooltips?.['opps_menu_matches'],
          rightAlign: true,
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Menu Matches',
        size: 110,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        accessorKey: 'total_ltv',
        meta: {
          tooltip: tooltips?.['opps_total_ltv'],
          headerTitle: 'Lifetime Total Value',
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: () => {
          return chainsSummary?.avg_total_ltv ? (
            <>
              <div style={{ marginTop: '12px' }}>Lifetime Total Value</div>
              <span>Avg: {formatUsd(chainsSummary?.avg_total_ltv)}</span>
            </>
          ) : (
            <span>Lifetime Total Value</span>
          )
        },
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'brand_ltv',
        meta: {
          tooltip: tooltips?.['opps_brand_ltv'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: 'Lifetime Brand Value',
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
        size: 150,
      },
      {
        accessorKey: 'revenue_ltv',
        meta: {
          tooltip: tooltips?.['opps_revenue_ltv'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: 'Lifetime Revenue Value',
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },

        size: 150,
      },
      {
        accessorKey: 'one_year_total_value',
        meta: {
          tooltip: tooltips?.['opps_taro_plus_tabo'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },

        header: '1yr Total Value',
        accessorFn: (row) => row?.one_year_total_value,
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'taro',
        meta: {
          tooltip: tooltips?.['opps_taro'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: '1yr Revenue Value',
        accessorFn: (row) => row?.taro,
        size: 180,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'tabo',
        meta: {
          tooltip: tooltips?.['opps_tabo'],
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: '1yr Brand Value',
        accessorFn: (row) => row?.tabo,
        size: 150,
        cell: (info) => {
          const value = info.getValue()

          return value ? formatUsd(value) : '-'
        },
      },
      {
        accessorKey: 'pounds_per_year',
        meta: {
          tooltip: tooltips?.['opps_pounds_per_year'],
          headerTitle: '1yr Volume (lbs)',
          rightAlign: true,
          headerGroup: 'Predicted Values',
        },
        header: () => {
          return chainsSummary?.avg_pounds_per_year ? (
            <>
              <div style={{ marginTop: '12px' }}>1yr Volume (lbs)</div>
              <span className={'text-xs'}>
                Avg: {formatInteger(chainsSummary?.avg_pounds_per_year)}
              </span>
            </>
          ) : (
            <span>1yr Volume (lbs)</span>
          )
        },
        size: 150,
        cell: (info) =>
          info.getValue() ? formatInteger(info.getValue()) : '-',
      },
      {
        accessorKey: 'note_count',
        meta: {
          tooltip: tooltips?.['opps_note_count'],
          rightAlign: true,
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Notes',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      },
      {
        accessorKey: 'contact_count',
        meta: {
          tooltip: tooltips?.['opps_contact_count'],
          rightAlign: true,
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Contacts',
        size: 110,
        cell: (info) => {
          return info.getValue() || '-'
        },
      },
      {
        id: 'deal_count',
        meta: {
          tooltip: tooltips?.['opps_deals_count'],
          rightAlign: true,
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Deals',
        size: 110,
        cell: (info) => {
          return info.row.original.deal_count || '-'
        },
      },
      {
        id: 'sales_stages',
        meta: {
          tooltip: tooltips?.['opps_sales_stages'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Sales Stages',
        size: 260,
        cell: (info) => {
          return (
            <SaleStagesCellFromDeals
              deals={info.row.original?.deals}
              sortAsc={
                sorting?.length && sorting[0].id === 'sales_stages'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      },
      {
        id: 'account_owners',
        meta: {
          tooltip: tooltips?.['opps_account_owners'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Account Owners',
        size: 260,
        cell: (info) => {
          const deals = info.row.original?.deals
          return <AccountOwnerCell deals={deals} />
        },
      },
      {
        accessorKey: 'tags',
        meta: {
          tooltip: tooltips?.['opps_tags'],
          headerGroup: 'Campaigns and Deals',
        },
        header: 'Tags',
        size: 260,
        cell: (info) => {
          return (
            <TaglistCell
              taglist={info.row.original?.taglist}
              asc={
                sorting?.length && sorting[0].id === 'tags'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      },
      {
        accessorKey: 'domain',
        meta: {
          tooltip: tooltips?.['opps_domain'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Domain',
        size: 200,
        cell: (info) => {
          const val = info.getValue()
          return val ? (
            <Anchor href={'//' + val} target="_blank">
              {val}
            </Anchor>
          ) : (
            '-'
          )
        },
      },
      {
        accessorKey: 'cuisine_50',
        meta: {
          tooltip: tooltips?.['opps_cuisine_50'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Cuisine',
        size: 150,
        cell: defaultCell,
      },
      {
        accessorKey: 'velocity_group',
        meta: {
          tooltip: tooltips?.['opps_velocity_group'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Predicted Traffic',
        size: 200,
        cell: (info) => <PredictedTrafficBadge value={info.getValue()} />,
      },
      {
        accessorKey: 'rating',
        meta: {
          tooltip: tooltips?.['opps_rating'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Rating',
        size: 70,
        cell: (info) => (info.getValue() ? info.getValue().toFixed(2) : '-'),
      },
      {
        accessorKey: 'reviews_count',
        meta: {
          tooltip: tooltips?.['opps_reviews_count'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Total Reviews',
        size: 150,
        cell: (ctx) => (ctx.getValue() ? formatInteger(ctx.getValue()) : '-'),
      },
      {
        accessorKey: 'expense_category',
        meta: {
          tooltip: tooltips?.['opps_expense_category'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Expense Category',
        size: 100,
        cell: defaultCell,
      },
      {
        accessorKey: 'ingredients_matrix',
        meta: {
          tooltip: tooltips?.['opps_ingredients_matrix'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Menu Characteristics',
        size: 300,
        cell: (info) => {
          const ingredients = info.row.original.ingredients_matrix
          const display: string[] = []

          if (!ingredients) return null

          const ingredientsValuesWhitelist = menu_ingredients.map(
            (item) => item.value
          )

          Object.entries(ingredients)
            .filter(([key]) => ingredientsValuesWhitelist.includes(key))
            .map(([key, value]) => {
              if (Number(value) > 0) display.push(key)
            })

          return (
            <OverflownText maxLines={2}>{display.join(', ')}</OverflownText>
          )
        },
      },
      {
        id: 'instagram_url',
        accessorKey: 'instagram.url',
        meta: {
          tooltip: tooltips?.['opps_instagram_url'],
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Handle',
        cell: (info) => {
          let value = info.row.original.instagram?.url
          if (!value) return '-'

          // Get handle from value, value is a url that can end on / or not
          if (value.endsWith('/')) {
            value = value.slice(0, -1)
          }
          const handle = '@' + value.split('/').pop()

          return (
            <Anchor href={value} target="_blank">
              {handle}
            </Anchor>
          )
        },
      },
      {
        id: 'instagram_followers',
        accessorKey: 'instagram.followers',
        meta: {
          tooltip: tooltips?.['opps_instagram_followers'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Followers',
        cell: (info) => {
          const value = info.row.original.instagram?.followers
          if (!value) return '-'
          return formatInteger(value)
        },
      },
      {
        id: 'instagram_following',
        accessorKey: 'chain.instagram.following',
        meta: {
          tooltip: tooltips?.['opps_instagram_followers'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Following',
        cell: (info) => {
          const value = info.row.original.instagram?.following
          if (!value) return '-'
          return formatInteger(value)
        },
      },
      {
        id: 'instagram_posts',
        accessorKey: 'instagram.posts',
        meta: {
          tooltip: tooltips?.['opps_instagram_posts'],
          rightAlign: true,
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Posts',
        cell: (info) => {
          const value = info.row.original.instagram?.posts
          if (!value) return '-'
          return formatInteger(value)
        },
      },
      {
        id: 'instagram_text_percentile',
        accessorKey: 'instagram.text_percentile',
        meta: {
          tooltip: tooltips?.['opps_instagram_text_percentile'],
          headerGroup: 'Reputation Data',
        },
        header: 'Instagram Ranking',
        size: 200,
        cell: (info) => <PredictedTrafficBadge value={info.getValue()} />,
      },
      {
        accessorKey: 'distributors',
        meta: {
          tooltip: tooltips?.['opps_distributors'],
          headerGroup: 'Opportunity Attributes',
        },
        header: 'Known Distributors',
        size: 260,
        cell: (info) => {
          return (
            <DistributorsCell
              distributors={info.row.original?.distributors}
              asc={
                sorting?.length && sorting[0].id === 'distributors'
                  ? !sorting[0].desc
                  : undefined
              }
            />
          )
        },
      },
      {
        accessorKey: 'country_summary',
        meta: {
          tooltip: tooltips?.['opps_country_summary'],
          headerGroup: 'Location Data',
        },
        header: 'Country Summary',
        size: 300,
        cell: (info) => {
          const countries = info.getValue()
          if (!countries) return '-'

          const countriesSorted = countries?.split(', ').sort()
          const display = countriesSorted?.slice(0, 25).join(', ')
          const totalCountries = countriesSorted?.length
          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalCountries} more)`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      },
      {
        accessorKey: 'state_summary',
        meta: {
          tooltip: tooltips?.['opps_state_summary'],
          headerGroup: 'Location Data',
        },
        header: 'State Summary',
        size: 300,
        cell: (info) =>
          info.getValue() ? (
            <OverflownText maxLines={2}>{info.getValue()}</OverflownText>
          ) : (
            '-'
          ),
      },
      {
        accessorKey: 'city_summary',
        meta: {
          tooltip: tooltips?.['opps_city_summary'],
          headerGroup: 'Location Data',
        },
        header: 'City Summary',
        size: 300,
        cell: (info) => {
          const cities = info.getValue()
          if (!cities) return '-'

          const citiesSorted = cities?.split(', ').sort()
          const display = citiesSorted?.slice(0, 25).join(', ')
          let totalCities = citiesSorted?.length
          if (totalCities > 1000) {
            totalCities = Math.floor(totalCities / 1000)
            totalCities = totalCities + 'k'
          }
          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalCities} more)`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      },
      {
        accessorKey: 'zip_summary',
        meta: {
          tooltip: tooltips?.['opps_zip_summary'],
          headerGroup: 'Location Data',
        },
        header: 'Zip Summary',
        size: 300,
        cell: (info) => {
          const zips = info.getValue()
          if (!zips) return '-'

          const zipSorted = zips?.split(', ').sort()
          const display = zipSorted?.slice(0, 25).join(', ')
          let totalZipCodes = zipSorted?.length
          if (totalZipCodes > 1000) {
            totalZipCodes = Math.floor(totalZipCodes / 1000)
            totalZipCodes = totalZipCodes + 'k'
          }
          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalZipCodes} more)`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      },
      {
        accessorKey: 'median_hhi',
        meta: {
          tooltip: tooltips?.['opps_median_hhi'],
          headerGroup: 'Location Data',
        },
        header: 'Median Household Income',
        cell: (info) => formatUsd(info.getValue()),
      },
      {
        accessorKey: 'pop_density',
        meta: {
          tooltip: tooltips?.['opps_pop_density'],
          headerGroup: 'Location Data',
        },
        header: 'Population Density',
        cell: (info) => formatInteger(info.getValue() ?? 0) + '/mi²',
      },
      {
        accessorKey: 'hh_gt100k',
        meta: {
          tooltip: tooltips?.['opps_hh_gt100k'],
          headerGroup: 'Location Data',
        },
        header: 'High Household Income',
        cell: (info) => {
          const value = info.getValue() * 100
          formatPercentage(value ?? 0) + '%'
        },
      },
      {
        header: 'Featured On',
        meta: {
          tooltip: tooltips?.['opps_chain_featured_on'],
          headerGroup: 'Reputation Data',
        },
        id: 'reputation_data',
        size: 200,
        cell: (info) => {
          const { michelin_ids_count, eater_ids_count, infatuation_ids_count } =
            // eslint-disable-next-line no-unsafe-optional-chaining
            info.row?.original
          const reputationPlatforms = [
            michelin_ids_count && 'Michelin',
            eater_ids_count && 'Eater',
            infatuation_ids_count && 'Infatuation',
          ].filter(Boolean)

          if (reputationPlatforms.length === 0) return '-'
          return (
            <div
              style={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {reputationPlatforms.map((platform, index) => (
                <Badge key={index} className="!bg-green-500">
                  {platform}
                </Badge>
              ))}
            </div>
          )
        },
      },
      {
        header: 'Michelin Stars',
        meta: {
          tooltip: tooltips?.['opps_chain_michelin_stars'],
          headerGroup: 'Reputation Data',
        },
        id: 'michelin_stars_count',
        accessorKey: 'michelin_stars_count',
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (value === undefined || value === null) return '-'
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { michelin_ids_count } = info.row?.original
          if (!michelin_ids_count) return '-'
          return value
        },
      },
      {
        accessorKey: 'campaigns',
        header: 'Campaigns',
        meta: {
          headerGroup: 'Campaigns and Deals',
        },
        cell: (info) => {
          return <CampaignsCell campaigns={info.getValue()} />
        },
        size: 260,
      },
      // {
      //   header: 'National',
      //   accessorKey: 'state_summary_length',
      //   cell: (info) => (info.getValue() >= 190 ? 'Yes' : 'No'),
      // },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] satisfies ColumnDef<Chain, any>[]

    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }

    if (!featureFlag.enableInstagremData) {
      cols = cols.filter((col) => !col.id?.includes('instagram'))
    }

    if (!featureFlag.enableInstagramPercentile) {
      cols = cols.filter((col) => col.id !== 'instagram_text_percentile')
    }

    if (!featureFlag.shouldShowMenu) {
      cols = cols.filter((col) => col.id !== 'menu')
    }

    if (!featureFlag.shouldShowMenuSize) {
      cols = cols.filter((col) => col.id !== 'menu_length')
    }

    if (!featureFlag.shouldShowReputationData) {
      cols = cols.filter(
        (col) =>
          !['reputation_data', 'michelin_stars_count'].includes(col?.id ?? '')
      )
    }

    return cols
  }, [
    data,
    selectedProducts,
    sorting,
    preferences,
    featureFlag.enableInstagremData,
    featureFlag.shouldShowMenu,
    featureFlag.shouldShowMenuSize,
    featureFlag.shouldShowReputationData,
  ])

  return columns
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`
